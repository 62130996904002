import firebase from "./firebase-setup";
import FirebaseFetcher from "./firebase-fetcher";

const MINUTE = 1000 * 60;
// Don't fetch more frequently than every these many minutes
const FETCH_LIMIT = MINUTE * 10;

const fixNumbers = (course) => {
    course?.properties?.variants?.forEach((variant) => {
        variant.distance = Number(variant.distance);
        if (Number.isNaN(variant.distance)) {
            variant.distance = null;
        }
        if (variant.stravaSegment) {
            variant.stravaSegment = Number(variant.stravaSegment);
            if (Number.isNaN(variant.stravaSegment)) {
                variant.stravaSegment = null;
            }
        } else {
            variant.stravaSegment = null;
        }
    });
};

export const fetchCourses = async (db) => {
    // Check if recenctly fetched
    let lastFetch = await db.getObject("lastFetch", "meta");
    lastFetch = lastFetch && parseInt(lastFetch.value);
    const now = Date.now();
    if (lastFetch) {
        if (now - lastFetch < FETCH_LIMIT) {
            // Fetched recently, using local data only
            console.log("Fetched recently, using local data only.");
            return;
        }
    }

    // Fetch only courses that hasn't already been fetched
    const lastUpdate = await db.getObject("lastUpdate", "meta");
    // Add one millisecond so the last course isn't sent again.
    const since = lastUpdate && parseInt(lastUpdate.value) + 1;
    const fetcher = new FirebaseFetcher();
    const newCourses = await fetcher.fetchCourses(since);
    await db.updateObject({ id: "lastFetch", value: now }, "meta");
    if (newCourses.length === 0) {
        console.log("No new courses found on server.");
        return;
    }
    console.log(`Found ${newCourses?.length} new courses on server, saving locally.`);
    for (const course of newCourses) {
        await db.updateObject(course, "features");
    }
    newCourses.sort((a, b) => b.properties.modified - a.properties.modified);
    const newest = newCourses[0].properties.modified;
    await db.updateObject({ id: "lastUpdate", value: newest }, "meta");

    return newCourses;
};

export const uploadCourse = async (course, db, user) => {
    fixNumbers(course);
    course.properties.modifiedBy = user?.uid;
    course.properties.modified = firebase.database.ServerValue.TIMESTAMP;
    course.properties.createdBy = user?.uid;
    course.properties.created = course.properties.modified;
    const fetcher = new FirebaseFetcher(user);
    await fetcher.uploadCourse(course);
    // Upload to firebase successful, save to local database
    // Local database needs regular timestamp
    course.properties.modified = Date.now();
    course.properties.created = course.properties.modified;
    await db.updateObject(course, "features");
    console.log(`Uploaded course ${course.properties.name} (${course.id})`);
};

export const updateCourse = async (course, db, user) => {
    fixNumbers(course);
    course.properties.modifiedBy = user?.uid;
    course.properties.modified = firebase.database.ServerValue.TIMESTAMP;
    const fetcher = new FirebaseFetcher(user);
    await fetcher.updateCourse(course);
    course.properties.modified = Date.now();
    await db.updateObject(course, "features");
    console.log(`Updated course ${course.properties.name} (${course.id})`);
};

export const removeCourse = async (course, db, user) => {
    const fetcher = new FirebaseFetcher(user);
    await fetcher.removeCourse(course);
    await db.delete(course.id, "features");
    console.log(`Deleted course ${course.properties.name} (${course.id})`);
};
