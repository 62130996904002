import clsx from "clsx";
import { AutosizedTextarea } from "./autosized-textarea";

import styles from "./textinput.scss";

export const TextInput = ({ className, title, multiline, fullWidth, value, onChange, ...other }) => {
    return (
        <div>
            <h4 className={styles.title}>{title}</h4>
            {multiline ? (
                <AutosizedTextarea
                    type="text"
                    className={clsx(className, styles.input, { [styles.fullWidth]: fullWidth })}
                    value={value ?? ""}
                    onChange={(event) => onChange(event.target.value)}
                    {...other}
                />
            ) : (
                <input
                    type="text"
                    className={clsx(className, styles.input, { [styles.fullWidth]: fullWidth })}
                    value={value ?? ""}
                    onChange={(event) => onChange(event.target.value)}
                    {...other}
                />
            )}
        </div>
    );
};
