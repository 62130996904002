import { memo } from "preact/compat";
import { useContext } from "preact/hooks";
import { UserContext } from "./user-context";
import clsx from "clsx";

import { Link } from "./link";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStrava } from "@fortawesome/free-brands-svg-icons";
import { faLink, faEdit } from "@fortawesome/free-solid-svg-icons";

import styles from "./course-popup.scss";
import { Popup } from "./popup";

const formatDistance = (distance) => {
    if (distance) {
        const kilometers = distance / 1000;
        // Round to 0-2 decimals
        return Math.round(kilometers * 100) / 100;
    }
    return null;
};

const MARKER_HEIGHT = 48;

export const CoursePopup = memo(({ course, onClose }) => {
    const { user } = useContext(UserContext);

    if (!course) {
        return null;
    }

    return (
        <Popup className={styles.popup} translateY={-MARKER_HEIGHT} onClose={onClose}>
            <div className={clsx(styles.section, styles.header)}>
                <h3 className={styles.title}>{course.properties.name}</h3>
            </div>
            <div className={styles.section}>
                <p className={styles.description}>{course.properties.description}</p>
            </div>
            <table className={clsx(styles.section, styles.variants)}>
                {course.properties.variants?.map((variant) => (
                    <tr>
                        <td>
                            <p>{variant.name}</p>
                        </td>
                        <td>
                            <p>{formatDistance(variant.distance)}km</p>
                        </td>
                        {variant.stravaSegment && (
                            <td>
                                <Link
                                    href={`https://strava.com/segments/${variant.stravaSegment}`}
                                    icon={<FontAwesomeIcon icon={faStrava} />}
                                    text="GPS-rutt"
                                />
                            </td>
                        )}
                    </tr>
                ))}
            </table>
            <div className={clsx(styles.section, styles.links)}>
                <Link
                    href={`https://google.com/maps/?q=${course.geometry.coordinates[1]},${course.geometry.coordinates[0]}`}
                    icon={<FontAwesomeIcon icon={faLink} />}
                    text="Öppna i Google Maps"
                />
            </div>
            <div className={clsx(styles.section, styles.footer)}>
                {!!user && (
                    <Link
                        className={styles.editLink}
                        href={`/edit/${course.id}`}
                        icon={<FontAwesomeIcon icon={faEdit} />}
                        text="Redigera"
                    />
                )}
                <p className={styles.modified}>
                    Senast redigerad {new Date(course.properties.modified).toLocaleDateString()}
                </p>
            </div>
        </Popup>
    );
});
