import clsx from "clsx";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";

import styles from "./popup.scss";

const CHEVRON_HEIGHT = 17;

export const Popup = ({ className, children, onClose, translateY }) => {
    const yOffset = -CHEVRON_HEIGHT + (translateY ?? 0);
    return (
        <div
            style={{
                transform: `translateY(${yOffset}px)`,
                paddingTop: `${-yOffset}px`, // needed for auto-pan
            }}
        >
            <div id="asdf" className={clsx(className, styles.container)}>
                {typeof onClose === "function" && (
                    <FontAwesomeIcon className={styles.closeButton} onClick={onClose} icon={faTimesCircle} />
                )}
                {children}
            </div>
        </div>
    );
};
