import { memo } from "preact/compat";
import { useContext, useEffect } from "preact/hooks";
import { MapContext } from "../Map";
import olClickZoomInteraction from "./olClickZoomInteraction";

const DoubleTapZoomInteraction = ({ disableDoubleClick }) => {
    const { map } = useContext(MapContext);

    useEffect(() => {
        let interaction;
        if (map) {
            interaction = olClickZoomInteraction(map, disableDoubleClick);
            map.addInteraction(interaction);
        }
        return () => {
            interaction && map?.removeInteraction(interaction);
        };
    }, [map, disableDoubleClick]);

    return null;
};

export default memo(DoubleTapZoomInteraction);
