/**
 * A local database backed by IndexedDB.
 */
export default class LocalDatabase {
    /**
     * Creates a local database backed by IndexedDB.
     * You must call {@link LocalDatabase#initialize|initialize} before using the database.
     *
     * @param {string[]} objectStores Names of the object stores the database should contain.
     */
    constructor(name, objectStores) {
        if (!window.indexedDB) {
            reject(new Error("Indexed DB not supported in this browser."));
        }
        this._name = name;
        this._objectStores = objectStores;
    }

    /**
     * This must be called before the database can be used.
     *
     * @returns {Promise} A promise resolving to whether the initialization was successful.
     */
    initialize() {
        return new Promise((resolve, reject) => {
            let request = window.indexedDB.open(this._name);

            request.onupgradeneeded = (event) => {
                let db = request.result;
                let options = {
                    keyPath: "id",
                    autoIncrement: true,
                };
                for (let store of this._objectStores) {
                    db.createObjectStore(store, options);
                }
            };
            request.onerror = (event) => {
                reject(new Error("There was an error opening the database."));
            };
            request.onsuccess = (event) => {
                this._db = request.result;
                resolve(this);
            };
        });
    }

    /**
     * Counts the number of objects in a store.
     *
     * @param {string} store The store to count objects in.
     *
     * @returns {Promise} A Promise resolving to the count.
     */
    count(store) {
        return new Promise((resolve, reject) => {
            if (!this._db) {
                reject(new Error("Database not initialized."));
            }
            let tx = this._db.transaction(store, "readonly");
            let objectStore = tx.objectStore(store);

            let request = objectStore.count();
            request.onerror = (event) => {
                reject(request.error);
            };
            request.onsuccess = (event) => {
                resolve(request.result);
            };
        });
    }

    /**
     * Adds a new object to the database.
     *
     * @param {Object} obj   The object to add.
     * @param {string} store The object store to add the object to.
     *
     * @returns {Promise} A promise resolving to the id (index) of the added
     *                    object.
     */
    addObject(obj, store, update = false) {
        return new Promise((resolve, reject) => {
            if (!this._db) {
                reject(new Error("Database not initialized."));
            }
            let tx = this._db.transaction(store, "readwrite");
            let objectStore = tx.objectStore(store);

            let request;
            if (update) {
                request = objectStore.put(obj);
            } else {
                request = objectStore.add(obj);
            }
            request.onerror = (event) => {
                reject(request.error);
            };
            request.onsuccess = (event) => {
                obj.id = request.result;
                resolve(request.result);
            };
        });
    }

    /**
     * Updates an existing object in the database.
     *
     * If an object with the same id doesn't exist,
     * it will be inserted as a new object.
     *
     * @param {Object} obj   The object to update.
     * @param {string} store The object store the object exists in.
     *
     * @returns {Promise} A promise resolving to the id (index) of the updated object.
     */
    updateObject(obj, store) {
        return this.addObject(obj, store, true);
    }

    /**
     * Adds multiple new objects to the database.
     *
     * @param {Object[]} objs The objects to add.
     * @param {string} store  The object store to add the objects to.
     *
     * @returns A promise resolving to the ids (indices) of the added objects.
     */
    addObjects(objs, store, update = false) {
        return new Promise((resolve, reject) => {
            if (!this._db) {
                reject(new Error("Database not initialized."));
            }
            let tx = this._db.transaction(store, "readwrite");
            let objectStore = tx.objectStore(store);
            let ids = [];

            for (let obj of objs) {
                let request;
                if (update) {
                    request = objectStore.put(obj);
                } else {
                    request = objectStore.add(obj);
                }
                request.onsuccess = (event) => {
                    objs[ids.length].id = request.result;
                    ids.push(request.result);
                };
            }

            tx.onabort = (event) => {
                reject(tx.error);
            };
            tx.oncomplete = (event) => {
                resolve(ids);
            };
        });
    }

    /**
     * Updates multiple existing objects in the database.
     *
     * If an object with the same id doesn't exist,
     * it will be inserted as a new object.
     *
     * @param {Object[]} objs The objects to update.
     * @param {string} store  The object store the objects exists in.
     *
     * @returns {Promise} A promise resolving to the id's (index) of the updated objects.
     */
    updateObjects(objs, store) {
        return this.addObjects(objs, store, true);
    }

    /**
     * Retrieves an object from the database.
     *
     * @param {string|number} id The id of the object to retrieve.
     * @param {string} store The object store the object exists in.
     *
     * @returns {Promise} A promise resolving to the object, or undefined if the object does not exist.
     */
    getObject(id, store) {
        return new Promise((resolve, reject) => {
            if (!this._db) {
                reject(new Error("Database not initialized."));
            }
            let tx = this._db.transaction(store, "readonly");
            let objectStore = tx.objectStore(store);

            let request = objectStore.get(id);
            request.onerror = (event) => {
                reject(request.error);
            };
            request.onsuccess = (event) => {
                resolve(request.result);
            };
        });
    }

    /**
     * Retrieves multiple objects from the database.
     *
     * @param {string[]|number[]} ids   An array of the IDs of the objects to retrieve.
     * @param {string} store The object store the objects exists in.
     *
     * @returns {Promise} A promise resolving to the objects, or an empty array if the objects do not exist.
     */
    getObjects(ids, store) {
        return new Promise((resolve, reject) => {
            if (!this._db) {
                reject(new Error("Database not initialized."));
            }
            let tx = this._db.transaction(store, "readonly");
            let objectStore = tx.objectStore(store);

            let objs = [];

            for (let id of ids) {
                let request = objectStore.get(id);
                request.onerror = (event) => {
                    reject(request.error);
                };
                request.onsuccess = (event) => {
                    if (request.result) {
                        objs.push(request.result);
                    } else {
                        reject(new Error("Object with id " + id + " does not exist"));
                    }
                };
            }

            tx.oncomplete = (event) => {
                resolve(objs);
            };
        });
    }

    /**
     * Retrieves all object in a store.
     *
     * @param {string} store The object store to retreive objects from.
     *
     * @returns {Promise} A Promise resolving to an array of all objects in the store.
     */
    getAll(store) {
        return new Promise((resolve, reject) => {
            if (!this._db) {
                reject(new Error("Database not initialized."));
            }
            let tx = this._db.transaction(store, "readonly");
            let objectStore = tx.objectStore(store);

            let request = objectStore.getAll();
            request.onerror = (event) => {
                reject(request.error);
            };
            request.onsuccess = (event) => {
                resolve(request.result);
            };
        });
    }

    /**
     * Deletes all object in a store.
     *
     * @param {string} store The store to clear.
     *
     * @returns {Promise} A promise resolving to whether the clear was successful.
     */
    clear(store) {
        return new Promise((resolve, reject) => {
            let tx = this._db.transaction(store, "readwrite");
            let objectStore = tx.objectStore(store);

            let request = objectStore.clear();
            request.onerror = (event) => {
                reject(request.error);
            };
            request.onsuccess = (event) => {
                resolve(true);
            };
        });
    }

    /**
     * Deletes an object from the database.
     *
     * @param {number|string} id The ID of the object to delete.
     * @param {string} store The object store that the object exists in.
     *
     * @returns {Promise} A Promise resolving to whether the removal was
     *                    successful.
     */
    delete(id, store) {
        return new Promise((resolve, reject) => {
            let tx = this._db.transaction(store, "readwrite");
            let objectStore = tx.objectStore(store);

            let request = objectStore.delete(id);
            request.onerror = (event) => {
                reject(request.error);
            };
            request.onsuccess = (event) => {
                resolve(true);
            };
        });
    }

    /**
     * Deletes an object from the database.
     *
     * @param {Object} obj The object to delete (must contain id-field).
     * @param {string} store The object store that the object exists in.
     *
     * @returns {Promise} A Promise resolving to whether the removal was
     *                    successful.
     */
    deleteObject(obj, store) {
        return new Promise((resolve, reject) => {
            if (!obj || !obj.hasOwnProperty("id")) {
                reject(new Error("Object must have an ID."));
            }

            let tx = this._db.transaction(store, "readwrite");
            let objectStore = tx.objectStore(store);

            let request = objectStore.delete(obj.id);
            request.onerror = (event) => {
                reject(request.error);
            };
            request.onsuccess = (event) => {
                resolve(true);
            };
        });
    }
}
