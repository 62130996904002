import { memo } from "preact/compat";
import clsx from "clsx";

import styles from "./link.scss";

export const Link = memo(({ className, icon, href, text }) => {
    return (
        <a className={clsx(className, styles.link)} href={href}>
            <span className={styles.icon}>{icon}</span>
            {text}
        </a>
    );
});
