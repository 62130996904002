import { useEffect } from "preact/hooks";
import { Footer } from "./footer";
import { Map } from "./map";
import { Navbar } from "./navbar";

import styles from "./home.scss";

export const Home = () => {
    useEffect(() => {
        const visited = localStorage.getItem("visited");
        localStorage.setItem("visited", "true");
        if (!visited) {
            window.location.href = "/info";
        }
    }, []);
    return (
        <div className={styles.conatiner}>
            <Navbar />
            <div className={styles.content}>
                <Map />
            </div>
            <Footer />
        </div>
    );
};
