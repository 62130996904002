import { Button } from "./button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Navbar } from "./navbar";
import { faMap } from "@fortawesome/free-solid-svg-icons";

import styles from "./info.scss";

export const Info = () => {
    return (
        <div className={styles.container}>
            <Navbar />
            <div className={styles.content}>
                <h2>Välkommen till reflexbanor.com</h2>
                <p>
                    Här samlar vi alla Sveriges reflexbanor på ett och samma ställe. En reflexbana
                    är en bana uppmärkt med reflexer som går på stigar eller rakt genom terrängen.
                    Hjälp gärna till att utöka samlingen!
                </p>
                <Button
                    variant="outlined"
                    icon={<FontAwesomeIcon icon={faMap} />}
                    text="Börja utforska"
                    onClick={() => (window.location.href = "/")}
                ></Button>
            </div>
        </div>
    );
};
