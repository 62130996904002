import { memo } from "preact/compat";
import { createContext } from "preact";
import { useContext, useEffect, useMemo, useRef } from "preact/hooks";

import { VectorLayerContext } from "../layer/Vector";

import { Cluster as OLClusterSource } from "ol/source";

export const ClusterSourceContext = createContext({ source: null });

// Make sure default geometry function works with null geometries
const defaultGeometryFunction = (feature) => {
    const geometry = feature.getGeometry();
    console.log("geometry", geometry);
    if (geometry) {
        const type = geometry.getType();
        if (type === "Point") {
            return geometry;
        }
        console.warn("The default `geometryFunction` can only handle `Point` geometries");
    }
    return null;
};

function Cluster({ distance, children }) {
    const clusterSource = useRef(new OLClusterSource({ geometryFunction: defaultGeometryFunction }));
    const { layer } = useContext(VectorLayerContext);
    const contextValue = useMemo(() => ({ source: clusterSource.current }), []);

    useEffect(() => {
        window.clusterSource = clusterSource.current;
    }, []);

    useEffect(() => {
        clusterSource.current.setDistance(distance);
    }, [distance]);

    useEffect(() => {
        layer.setSource(clusterSource.current);
        return () => {
            layer.setSource(null);
        };
    }, [layer]);

    return <ClusterSourceContext.Provider value={contextValue}>{children}</ClusterSourceContext.Provider>;
}

export default memo(Cluster);
