import clsx from "clsx";

import styles from "./button.scss";

export const Button = ({ variant = "filled", color, text, icon, className, ...other }) => {
    return (
        <button
            className={clsx(className, styles.button, {
                [styles.outlined]: variant === "outlined",
                [styles.text]: variant === "text",
                [styles.filled]: variant === "filled",
                [styles.primary]: color === "primary",
                [styles.destructive]: color === "destructive",
            })}
            type="button"
            {...other}
        >
            {icon && <span className={styles.icon}>{icon}</span>}
            {text}
        </button>
    );
};
