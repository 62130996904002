import firebase from "../js/firebase-setup";
import { createContext } from "preact";
import { useEffect, useMemo, useState } from "preact/hooks";

export const UserContext = createContext({ user: null });

export const UserContextProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const contextValue = useMemo(() => ({ user }), [user]);

    useEffect(() => {
        const unregisterAuthObserver = firebase.auth().onAuthStateChanged((user) => {
            setUser(user ?? null);
            if (user) {
                console.log(`signed in as ${user.displayName ?? "unknown"}`);
            }
        });
        return () => unregisterAuthObserver();
    }, []);

    return <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>;
};
