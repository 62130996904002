import { useContext } from "preact/hooks";
import { UserContext } from "./user-context";
import { Button } from "./button";
import { Popup } from "./popup";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

export const NewCoursePopup = ({ coordinates }) => {
    const { user } = useContext(UserContext);

    const handleAddCourse = () => {
        window.location.href = `/new/?lon=${coordinates[0]}&lat=${coordinates[1]}`;
    };

    return (
        <Popup>
            {user ? (
                <Button
                    variant="outlined"
                    text="Lägg till reflexbana"
                    icon={<FontAwesomeIcon icon={faPlus} />}
                    onClick={handleAddCourse}
                />
            ) : (
                <p>
                    <a href="/login">Logga in</a> för att kunna lägga till reflexbanor
                </p>
            )}
        </Popup>
    );
};
