import { createContext } from "preact";
import { useEffect, useState } from "preact/hooks";
import LocalDatabase from "../js/local-database";

export const DBContext = createContext({ db: null });

export const DBContextProvider = ({ children }) => {
    const [db, setDb] = useState(null);

    useEffect(() => {
        const init = async () => {
            try {
                const db = await new LocalDatabase("database", ["features", "meta"]).initialize();
                setDb(db);
            } catch (error) {
                console.error("error initializing local database", error);
                setError(error);
            }
        };
        init();
    }, []);

    return <DBContext.Provider value={{ db }}>{children}</DBContext.Provider>;
};
