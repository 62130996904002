import firebase, { uiConfig } from "../js/firebase-setup";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import { Navbar } from "./navbar";

import styles from "./login.scss";

export const Login = () => {
    return (
        <div className={styles.wrapper}>
            <Navbar />
            <div className={styles.container}>
                <h2>Logga in</h2>
                <p>
                    Vill du hjälpa till att utöka samlingen? Klicka knappen nedan och logga in med ditt Google-konto.
                    Sedan kan du klicka någonstans på kartan för att lägga till en reflexbana. Du kan även klicka på en
                    existerande reflexbana och redigera den.
                </p>
                <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
            </div>
        </div>
    );
};
