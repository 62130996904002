import { useCallback, useState } from "preact/hooks";
import { memo } from "preact/compat";
import { useCourses } from "../js/useCourses";

import { CoursePopup } from "./course-popup";
import { NewCoursePopup } from "./new-course-popup";

import { Feature as OLFeature, Map as OLMap, Overlay as OLOverlay } from "ol-preact";
import { Tile as OLTileLayer, Vector as OLVectorLayer } from "ol-preact/layer";
import { Vector as OLVectorSource } from "ol-preact/source";
import { DoubleTapZoomInteraction } from "ol-preact/custom";
import { pointStyle } from "../js/styles";
import { OSM } from "ol/source";
import { fromLonLat, toLonLat } from "ol/proj";
import { defaults as defaultControls } from "ol/control";

import styles from "./map.scss";

const source = new OSM();
const autoPanOptions = {
    animation: {
        duration: 250,
    },
};

const convertLonLat = (coordinates) => {
    if (Array.isArray(coordinates)) {
        return fromLonLat(coordinates);
    } else {
        return null;
    }
};

export const Map = memo(() => {
    const { courses } = useCourses();
    const [selectedCourse, setSelectedCourse] = useState(null);
    const [newCourseCoordinates, setNewCourseCoordinates] = useState(null);
    const [view, _] = useState(() => {
        let center = localStorage.getItem("map-center");
        if (center) {
            center = JSON.parse(center);
        } else {
            center = fromLonLat([16.1748, 58.5908]);
        }
        let resolution = localStorage.getItem("map-resolution");
        if (resolution) {
            resolution = parseFloat(resolution);
        } else {
            resolution = 100;
        }
        return { center, resolution };
    });

    const handleMapClick = useCallback(
        (event) => {
            const features = event.target.getFeaturesAtPixel(event.pixel);
            if (features.length > 0) {
                const course = courses.find((c) => c.id === features[0].getId());
                setNewCourseCoordinates(null);
                setSelectedCourse(course);
            } else if (selectedCourse) {
                setSelectedCourse(null);
            } else if (newCourseCoordinates) {
                setNewCourseCoordinates(null);
            } else {
                setNewCourseCoordinates(event.coordinate);
            }
        },
        [courses, selectedCourse, newCourseCoordinates],
    );
    const handlePointerMove = useCallback((event) => {
        const hit = event.target.hasFeatureAtPixel(event.pixel);
        event.target.getViewport().style.cursor = hit ? "pointer" : "";
    }, []);
    const handleMoveEnd = useCallback((event) => {
        const center = event.map.getView().getCenter();
        const resolution = event.map.getView().getResolution();
        localStorage.setItem("map-center", JSON.stringify(center));
        localStorage.setItem("map-resolution", `${resolution}`);
    }, []);
    const closePopup = useCallback(() => setSelectedCourse(null), []);

    const overlayCoordinates =
        convertLonLat(selectedCourse?.geometry?.coordinates) ?? newCourseCoordinates ?? null;
    let popup = null;
    if (selectedCourse) {
        popup = <CoursePopup course={selectedCourse} onClose={closePopup} />;
    }
    if (newCourseCoordinates) {
        popup = <NewCoursePopup coordinates={toLonLat(newCourseCoordinates)} />;
    }

    return (
        <OLMap
            className={styles.map}
            view={view}
            onTrueClick={handleMapClick}
            onPointerMove={handlePointerMove}
            onMoveEnd={handleMoveEnd}
            controls={defaultControls({ attribution: false })}
        >
            <DoubleTapZoomInteraction />
            <OLTileLayer source={source} zIndex={0} />
            <OLVectorLayer style={pointStyle} zIndex={10}>
                <OLVectorSource>
                    {courses.map((course) => (
                        <OLFeature
                            id={course.id}
                            geometry={course.geometry}
                            projection="EPSG:4326"
                        />
                    ))}
                </OLVectorSource>
            </OLVectorLayer>
            <OLOverlay
                position={overlayCoordinates}
                positioning="bottom-center"
                autoPan={autoPanOptions}
                className={styles.overlay}
            >
                {popup}
            </OLOverlay>
        </OLMap>
    );
});
