import { route } from "preact-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Button } from "./button";
import { TextInput } from "./textinput";

import styles from "./courseform.scss";

export const CourseForm = ({ title, course, onChange, onSubmit, onDelete }) => {
    const handleSubmit = (event) => {
        event.preventDefault();
        onSubmit(event);
    };
    const handleAddVariant = () =>
        onChange((course) => ({
            ...course,
            properties: {
                ...course.properties,
                variants: [...course.properties.variants, {}],
            },
        }));
    const handleRemoveVariant = (index) =>
        onChange((course) => {
            const variants = [...course.properties.variants];
            variants.splice(index, 1);
            return { ...course, properties: { ...course.properties, variants } };
        });
    const handleVariantChange = (key, index) => (value) =>
        onChange((course) => {
            const variants = [...course.properties.variants];
            variants[index] = { ...variants[index], [key]: value };
            return { ...course, properties: { ...course.properties, variants } };
        });
    const handleStravaSegmentChange = (index) => (value) => {
        const newValue = value
            .replace("https://", "")
            .replace("http://", "")
            .replace("www.", "")
            .replace("strava.com/segments/", "");
        handleVariantChange("stravaSegment", index)(newValue);
    };
    const handleCancel = () => {
        route("/", true);
    };

    return (
        <div className={styles.container}>
            <h2>{title}</h2>
            <form onSubmit={handleSubmit}>
                <div>
                    <TextInput
                        fullWidth
                        required
                        maxlength={100}
                        className={styles.textInput}
                        title="Reflexbanans namn"
                        value={course.properties.name}
                        onChange={(name) =>
                            onChange((course) => ({
                                ...course,
                                properties: { ...course.properties, name },
                            }))
                        }
                    />
                    <TextInput
                        fullWidth
                        multiline
                        maxlength={200}
                        className={styles.textInput}
                        title="Beskrivining"
                        placeholder="Banan börjar söder om vägen..."
                        value={course.properties.description}
                        onChange={(description) =>
                            onChange((course) => ({
                                ...course,
                                properties: { ...course.properties, description },
                            }))
                        }
                    />
                    <p className={styles.descriptionCharacterCount}>
                        {course.properties.description?.length ?? 0} / 200
                    </p>
                </div>
                <div>
                    {course.properties.variants.map((variant, index) => (
                        <div className={styles.variant}>
                            <h3>Variant {index + 1}</h3>
                            <div className={styles.inputs}>
                                <TextInput
                                    fullWidth
                                    required
                                    className={styles.textInput}
                                    title="Namn"
                                    value={variant.name}
                                    onChange={handleVariantChange("name", index)}
                                />
                                <TextInput
                                    fullWidth
                                    required
                                    className={styles.textInput}
                                    title="Längd i meter"
                                    placeHolder="8000"
                                    type="number"
                                    value={variant.distance}
                                    onChange={handleVariantChange("distance", index)}
                                />
                                <TextInput
                                    fullWidth
                                    className={styles.textInput}
                                    title="Strava segment"
                                    placeholder="16456283"
                                    value={variant.stravaSegment}
                                    onChange={handleStravaSegmentChange(index)}
                                />
                            </div>
                            <div className={styles.deleteVariantContainer}>
                                <Button
                                    variant="text"
                                    color="destructive"
                                    onClick={() => handleRemoveVariant(index)}
                                    text="Ta bort variant"
                                    icon={<FontAwesomeIcon icon={faTrash} />}
                                />
                            </div>
                        </div>
                    ))}
                </div>
                <div className={styles.buttons}>
                    <Button variant="outlined" onClick={handleAddVariant} text="Lägg till variant" />
                    <div />
                    <Button variant="filled" color="destructive" onClick={handleCancel} text="Avbryt" />
                    <Button variant="filled" color="primary" type="submit" text="Spara" />
                </div>
                {typeof onDelete === "function" && (
                    <div className={styles.deleteCourseContainer}>
                        <Button
                            variant="text"
                            color="destructive"
                            text="Ta bort reflexbanan"
                            icon={<FontAwesomeIcon icon={faBan} />}
                            onClick={onDelete}
                        />
                    </div>
                )}
            </form>
        </div>
    );
};
