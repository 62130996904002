import { useContext, useEffect, useState } from "preact/hooks";
import { DBContext } from "./db-context";
import { ErrorContext } from "./error-context";
import { UserContext } from "./user-context";
import { uploadCourse } from "../js/api";

import { CourseForm } from "./courseform";
import { Spinner } from "./spinner";

export const New = ({ lat, lon }) => {
    const { db } = useContext(DBContext);
    const { user } = useContext(UserContext);
    const { setError } = useContext(ErrorContext);
    const [course, setCourse] = useState(null);

    useEffect(() => {
        setCourse({
            type: "Feature",
            geometry: {
                type: "Point",
                coordinates: [lon, lat],
            },
            properties: {
                variants: [{}],
            },
        });
    }, [lat, lon]);

    const handleSubmit = async () => {
        try {
            console.log("upload", course);
            await uploadCourse(course, db, user);
            console.log("course uploaded, redirecting.");
            window.location.href = "/";
        } catch (error) {
            setError({
                error,
                alert: {
                    icon: "error",
                    title: "Hoppsan",
                    text: "Något gick fel. Kontrollera gärna att det du fyllt i stämmer.",
                    confirmButtonText: "OK",
                },
            });
        }
    };

    return course ? (
        <CourseForm title="Ny reflexbana" course={course} onChange={setCourse} onSubmit={handleSubmit} />
    ) : (
        <Spinner />
    );
};
