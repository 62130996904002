import Router from "preact-router";
import { DBContextProvider } from "./db-context";
import { ErrorContextProvider } from "./error-context";
import { UserContextProvider } from "./user-context";
import { Edit } from "./edit";
import { Home } from "./home";
import { Info } from "./info";
import { Login } from "./login";
import { New } from "./new";

export const App = () => {
    return (
        <ErrorContextProvider>
            <DBContextProvider>
                <UserContextProvider>
                    <Router>
                        <Home path="/" />
                        <Info path="/info" />
                        <Login path="/login" />
                        <Edit path="/edit/:id" />
                        <New path="/new/" />
                    </Router>
                </UserContextProvider>
            </DBContextProvider>
        </ErrorContextProvider>
    );
};
