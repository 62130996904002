import Swal from "sweetalert2/dist/sweetalert2.all";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);

import styles from "./alert.scss";
import clsx from "clsx";

export const presentAlert = ({ destructive, ...other }) => {
    return MySwal.fire({
        ...other,
        customClass: {
            popup: styles.container,
            title: styles.title,
            htmlContainer: styles.text,
            actions: styles.buttonContainer,
            confirmButton: clsx(styles.confirmButton, { [styles.destructiveColor]: destructive }),
        },
    });
};
