import { useContext, useEffect, useState } from "preact/hooks";
import { DBContext } from "../components/db-context";
import { fetchCourses } from "./api";

export const useCourses = () => {
    const { db } = useContext(DBContext);
    const [courses, setCourses] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        let cancelled = false;
        const fetchLocal = async () => {
            const courses = await db.getAll("features");
            console.log(`Loaded ${courses?.length} local courses.`);
            if (!cancelled) {
                setCourses(courses);
            }
        };
        const fetch = async () => {
            try {
                await fetchLocal();
                const newCourses = await fetchCourses(db);
                if (newCourses) {
                    await fetchLocal();
                }
            } catch (error) {
                console.error("error fetching courses", error);
                setError(error);
            }
        };
        if (db) {
            fetch();
        }
        return () => {
            cancelled = true;
        };
    }, [db]);

    return { courses, error };
};
