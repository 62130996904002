import { useCallback } from "preact/hooks";
import autosize from "autosize";

export const AutosizedTextarea = (props) => {
    const textAreaRef = useCallback((node) => {
        let cancelled = false;
        // Calling autosize immediately doesn't work on first render
        setTimeout(() => {
            if (!cancelled) {
                autosize(node);
            }
        }, 0);
        return () => {
            cancelled = true;
            autosize.destroy(node);
        };
    }, []);
    return <textarea {...props} ref={textAreaRef} />;
};
