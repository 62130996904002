import { useEffect, useState } from "preact/hooks";
import { useContext } from "react";
import { DBContext } from "./db-context";
import { ErrorContext } from "./error-context";
import { UserContext } from "./user-context";
import { removeCourse, updateCourse } from "../js/api";

import { CourseForm } from "./courseform";
import { Spinner } from "./spinner";
import { presentAlert } from "./alert";

export const Edit = ({ id }) => {
    const [course, setCourse] = useState(null);
    const { db } = useContext(DBContext);
    const { user } = useContext(UserContext);
    const { setError } = useContext(ErrorContext);

    useEffect(() => {
        const fetch = async () => {
            const course = await db.getObject(id, "features");
            setCourse(course);
        };
        if (db && id) {
            fetch();
        }
    }, [id, db]);
    const handleSubmit = async () => {
        try {
            console.log("update", course);
            await updateCourse(course, db, user);
            console.log("course updated, redirecting.");
            window.location.href = "/";
        } catch (error) {
            setError({
                error,
                alert: {
                    icon: "error",
                    title: "Hoppsan",
                    text: "Något gick fel. Kontrollera gärna att det du fyllt i stämmer.",
                    confirmButtonText: "OK",
                },
            });
        }
    };
    const handleDelete = async () => {
        try {
            console.log("delete", course);
            await removeCourse(course, db, user);
            console.log("course deleted, redirecting.");
            window.location.href = "/";
        } catch (error) {
            setError({
                error,
                alert: {
                    icon: "error",
                    title: "Hoppsan",
                    text: "Något gick fel. Försök igen senare.",
                    confirmButtonText: "OK",
                },
            });
        }
    };
    const confirmDelete = async () => {
        const result = await presentAlert({
            icon: "warning",
            title: "Radera reflexbanan",
            text: "Åtgärden går inte att ångra. Är du säker?",
            showCancelButton: true,
            cancelButtonText: "Avbryt",
            confirmButtonText: "Radera",
            destructive: true,
        });
        if (result.isConfirmed) {
            handleDelete();
        }
    };

    return course ? (
        <CourseForm
            title="Redigera reflexbana"
            course={course}
            onChange={setCourse}
            onSubmit={handleSubmit}
            onDelete={confirmDelete}
        />
    ) : (
        <Spinner />
    );
};
