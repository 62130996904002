import { Pointer as PointerInteraction } from "ol/interaction";
import { MapBrowserEvent } from "ol";

// Time between two down events to count as a double click
const DOUBLE_CLICK_TIME_TOLERANCE = 200;
// Sensitivity for dragging to zoom. Larger number = less sensitive.
const ZOOM_SCALE_FACTOR = 100;
// Duration for double click zoom animation
const ZOOM_ANIMATION_DURATION = 250;

const ClickZoom = (function (PointerInteraction) {
    function ClickZoom(options = {}) {
        PointerInteraction.call(this, {
            handleDownEvent: handleDownEvent,
            handleDragEvent: handleDragEvent,
            handleUpEvent: handleUpEvent,
        });
        this.handleMapClick = handleMapClick.bind(this);
        this._map = options.map;
        this._map.on("click", this.handleMapClick);

        this._lastMapClick = 0;
        this._pixel = null;
        this._zooming = false;

        this.onZoom = options.onZoom || (() => {});
        this.onDrag = options.onDrag || (() => {});
        this.onDoubleClick = options.onDoubleClick || (() => {});
    }

    if (PointerInteraction) ClickZoom.__proto__ = PointerInteraction;
    ClickZoom.prototype = Object.create(PointerInteraction && PointerInteraction.prototype);
    ClickZoom.prototype.constructor = ClickZoom;

    return ClickZoom;
})(PointerInteraction);

function handleDownEvent(event) {
    clearTimeout(this._singleClickTimeout);
    if (Date.now() - this._lastMapClick < DOUBLE_CLICK_TIME_TOLERANCE) {
        this._zooming = true;
        this._pixel = event.pixel;
        this._dragged = false;
        return true;
    }
    return false;
}

function handleDragEvent(event) {
    if (this._zooming) {
        const deltaY = event.pixel[1] - this._pixel[1];
        this.onZoom(deltaY, event.coordinate);
    }
    this._pixel[0] = event.pixel[0];
    this._pixel[1] = event.pixel[1];
    this._dragged = true;
}

function handleUpEvent(event) {
    if (this._zooming) {
        this._zooming = false;
    }
    if (!this._dragged) {
        // Double click
        this.onDoubleClick(event);
        this._handledClick = true;
    }
    return false;
}

function handleMapClick(event) {
    this._lastMapClick = Date.now();
    if (!this._handledClick) {
        this._singleClickTimeout = setTimeout(() => {
            const newEvent = new MapBrowserEvent(
                "trueclick",
                this._map,
                event.pointerEvent,
                event.dragging,
                event.framestate
            );
            newEvent.target = event.target;
            newEvent.coordinate = event.coordinate;
            newEvent.pixel = event.pixel;
            this._map.dispatchEvent(newEvent);
        }, DOUBLE_CLICK_TIME_TOLERANCE);
    }
    this._handledClick = false;
}

const olClickZoomInteraction = (map, disableDoubleClick) =>
    new ClickZoom({
        onZoom: (delta) => {
            const view = map.getView();
            const newZoom = view.getZoom() + delta / ZOOM_SCALE_FACTOR;
            view.setZoom(newZoom);
        },
        onDoubleClick: (event) => {
            if (!disableDoubleClick) {
                const view = map.getView();
                const center = view.getCenter();
                const newCenter = [(center[0] + event.coordinate[0]) / 2, (center[1] + event.coordinate[1]) / 2];
                view.animate({
                    zoom: view.getZoom() + 1,
                    center: newCenter,
                    duration: ZOOM_ANIMATION_DURATION,
                });
            }
        },
        map,
    });

export default olClickZoomInteraction;
