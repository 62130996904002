import { memo } from "preact/compat";
import { useEffect, useContext, useRef } from "preact/hooks";
import { MapContext } from "../Map";

import { Image as OLImageLayer } from "ol/layer";

function Image({ source, onPostRender }) {
    const layer = useRef(new OLImageLayer({ source }));
    const { map } = useContext(MapContext);

    useEffect(() => {
        map?.addLayer(layer.current);
        return () => {
            map?.removeLayer(layer.current);
        };
    }, [map]);

    useEffect(() => {
        layer.current.setSource(source);
    }, [source]);

    useEffect(() => {
        onPostRender && layer.current.on("postrender", onPostRender);
        return () => {
            onPostRender && layer.current.un("postrender", onPostRender);
        };
    }, []);

    return null;
}

export default memo(Image);
