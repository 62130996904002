import { memo } from "preact/compat";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

import styles from "./footer.scss";

export const Footer = memo(() => {
    return (
        <div className={styles.footer}>
            <p>
                &copy; <a href="https://tajgastudio.com">Tajga Studio AB</a>{" "}
                {new Date().getFullYear()}
                <a className={styles.icon} href="https://twitter.com/tajgastudio">
                    <FontAwesomeIcon icon={faTwitter} />
                </a>
                <a
                    className={styles.icon}
                    href="mailto:hej@tajgastudio.com?subject=Angående%20reflexbanor.com"
                >
                    <FontAwesomeIcon icon={faEnvelope} />
                </a>
            </p>
            <p>
                &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>{" "}
                contributors
            </p>
        </div>
    );
});
