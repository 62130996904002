import { createContext } from "preact";
import { memo } from "preact/compat";
import { useEffect, useRef, useState, useMemo } from "preact/hooks";

import { Map as OLMap, View as OLView } from "ol";

import "ol/ol.css";

export const MapContext = createContext({ map: null });

function Map({
    className,
    style,
    view,
    children,
    controls,
    interactions,
    onSingleClick,
    onTrueClick,
    onPointerMove,
    onRenderComplete,
    onMoveEnd,
    mapRef,
}) {
    const ref = useRef(null);
    const [map, setMap] = useState(null);

    const contextValue = useMemo(() => ({ map }), [map]);

    useEffect(() => {
        const map = new OLMap({
            target: ref.current,
            view: new OLView(view),
            controls,
            interactions,
        });
        window.map = map;
        if (mapRef) {
            mapRef.current = map;
        }
        setMap(map);
        return () => {
            map.setTarget(null);
        };
    }, [view, interactions, controls]);

    useEffect(() => {
        onRenderComplete && map?.on("rendercomplete", onRenderComplete);
        return () => {
            onRenderComplete && map?.un("rendercomplete", onRenderComplete);
        };
    }, [map, onRenderComplete]);

    useEffect(() => {
        onSingleClick && map?.on("singleclick", onSingleClick);
        return () => {
            onSingleClick && map?.un("singleclick", onSingleClick);
        };
    }, [map, onSingleClick]);

    useEffect(() => {
        onPointerMove && map?.on("pointermove", onPointerMove);
        return () => {
            onPointerMove && map?.un("pointermove", onPointerMove);
        };
    }, [map, onPointerMove]);

    useEffect(() => {
        onMoveEnd && map?.on("moveend", onMoveEnd);
        return () => {
            onMoveEnd && map?.un("moveend", onMoveEnd);
        };
    }, [map, onMoveEnd]);

    useEffect(() => {
        onTrueClick && map?.on("trueclick", onTrueClick);
        return () => {
            onTrueClick && map?.un("trueclick", onTrueClick);
        };
    }, [map, onTrueClick]);

    return (
        <MapContext.Provider value={contextValue}>
            <div className={className} style={style} ref={ref}>
                {children}
            </div>
        </MapContext.Provider>
    );
}

export default memo(Map);
