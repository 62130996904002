import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/database";

import firebaseConfig from "./firebase-config-demo.json";
firebase.initializeApp(firebaseConfig);

export const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: "popup",
    // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
    signInSuccessUrl: "/",
    // We will display Google and Facebook as auth providers.
    signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID],
};

export default firebase;
