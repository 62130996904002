import { createContext } from "preact";
import { useEffect, useState } from "preact/hooks";

import { presentAlert } from "./alert";

export const ErrorContext = createContext({ setError: null });

export const ErrorContextProvider = ({ children }) => {
    const [error, setError] = useState(null);

    useEffect(() => {
        if (error) {
            console.error(error.error);
            presentAlert({
                ...error.alert,
                didClose: () => setError(null),
            });
        }
    }, [error]);

    return <ErrorContext.Provider value={{ setError }}>{children}</ErrorContext.Provider>;
};
