import firebase from "../js/firebase-setup";
import { useContext } from "preact/hooks";
import { UserContext } from "./user-context";
import { route, useRouter } from "preact-router";
import { Button } from "./button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faMap, faSignOutAlt, faUser } from "@fortawesome/free-solid-svg-icons";

export const Navbar = () => {
    const { user } = useContext(UserContext);
    const [router] = useRouter();

    const handleLink = (url) => () => {
        route(url);
    };
    const handleSignOut = () => {
        console.log("signed out");
        firebase.auth().signOut();
    };

    const setOpacity = (ref) => {
        setTimeout(() => {
            // Delayed fade in seems to avoid blocky artefacts on iOS
            if (ref) {
                ref.style.opacity = "1.0";
            }
        }, 100);
    };

    return (
        <div id="header">
            <div class="brand" onClick={handleLink("/")}>
                <img src="/assets/logo.svg" alt="reflexbanor.com logotype" ref={setOpacity} />
                <h1>reflexbanor.com</h1>
            </div>
            <div class="buttons">
                <Button
                    onClick={handleLink("/")}
                    icon={<FontAwesomeIcon icon={faMap} />}
                    className={router.url === "/" ? "active" : undefined}
                />
                <Button
                    onClick={handleLink("/info")}
                    icon={<FontAwesomeIcon icon={faInfoCircle} />}
                    className={router.url === "/info" ? "active" : undefined}
                />
                {user ? (
                    <Button
                        onClick={handleSignOut}
                        icon={<FontAwesomeIcon icon={faSignOutAlt} />}
                        className={router.url === "/login" ? "active" : undefined}
                    />
                ) : (
                    <Button
                        onClick={handleLink("/login")}
                        icon={<FontAwesomeIcon icon={faUser} />}
                        className={router.url === "/login" ? "active" : undefined}
                    />
                )}
            </div>
        </div>
    );
};
