import { memo } from "preact/compat";
import { createContext } from "preact";
import { useRef, useContext, useEffect, useMemo } from "preact/hooks";

import { VectorLayerContext } from "../layer/Vector";
import { ClusterSourceContext } from "./Cluster";

import { Vector as OLVectorSource } from "ol/source";

export const VectorSourceContext = createContext({ source: null });

function Vector({ children }) {
    const source = useRef(new OLVectorSource());
    const { layer } = useContext(VectorLayerContext);
    const { source: clusterSource } = useContext(ClusterSourceContext);
    const contextValue = useMemo(() => ({ source: source.current }), []);

    useEffect(() => {
        const object = clusterSource ?? layer;
        object.setSource(source.current);
        return () => {
            object.setSource(null);
        };
    }, [layer, clusterSource]);

    return <VectorSourceContext.Provider value={contextValue}>{children}</VectorSourceContext.Provider>;
}

export default memo(Vector);
