import { memo } from "preact/compat";
import { createContext } from "preact";
import { useEffect, useContext, useRef, useMemo } from "preact/hooks";
import { MapContext } from "../Map";

import { VectorImage as OLVectorImageLayer } from "ol/layer";

export const VectorLayerContext = createContext({ layer: null });

function Vector({ zIndex, style, onPostRender, children }) {
    const layer = useRef(new OLVectorImageLayer());
    const { map } = useContext(MapContext);
    const contextValue = useMemo(() => ({ layer: layer.current }), []);

    useEffect(() => {
        map?.addLayer(layer.current);
        return () => {
            map?.removeLayer(layer.current);
        };
    }, [map]);

    useEffect(() => {
        layer.current.setZIndex(zIndex ?? 0);
    }, [zIndex]);

    useEffect(() => {
        layer.current.setStyle(style || undefined);
    }, [style]);

    useEffect(() => {
        onPostRender && layer.current.on("postrender", onPostRender);
        return () => {
            onPostRender && layer.current.un("postrender", onPostRender);
        };
    }, [onPostRender]);

    return <VectorLayerContext.Provider value={contextValue}>{children}</VectorLayerContext.Provider>;
}

export default memo(Vector);
