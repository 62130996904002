import firebase from "./firebase-setup";

export default class FirebaseFetcher {
    constructor(user) {
        this._user = user;
    }

    /**
     * Fetch all courses from the Firebase database.
     *
     * @param since Return only courses that has been modified since this date.
     *              Format: Number of milliseconds since January 1 1970.
     *
     * @returns An array of GeoJSON features, one for each course.
     */
    async fetchCourses(since) {
        return this._fetch("/features", "/properties/modified", since);
    }

    async fetchDeletedCourses(since) {
        return this._fetch("/deleted", "/timestamp", since);
    }

    async _fetch(refName, order, since) {
        let ref = firebase.database().ref(refName).orderByChild(order);
        if (since) {
            ref = ref.startAt(since);
        }

        let snapshot = await ref.once("value");
        let features = [];
        if (snapshot.hasChildren()) {
            snapshot.forEach(function (childsnap) {
                let feature = childsnap.val();
                feature.id = childsnap.key;
                features.push(feature);
            });
        }
        return features;
    }

    /**
     * Upload a new course to the firebase database.
     *
     * @param course A GeoJSON feature representing the course.
     *
     * @returns true if the upload was successful, else an error.
     */
    async uploadCourse(course) {
        if (!this._user || !this._user.uid) {
            throw new Error("Non-read operations requires a user");
        }
        if (course.id) {
            throw new Error("Course already has an ID. Use updateCourse.");
        }
        let ref = firebase.database().ref("features");
        let snapshot = await ref.push(course);
        course.id = snapshot.key;
        return true;
    }

    /**
     * Update an existing course in the firebase database.
     *
     * @param course A GeoJSON feature representing the course.
     *
     * @returns true if the upload was successful, else an error.
     */
    async updateCourse(course) {
        if (!this._user || !this._user.uid) {
            throw new Error("Non-read operations requires a user");
        }
        if (!course.id) {
            throw new Error("Course does not have an ID. Use uploadCourse");
        }
        let ref = firebase.database().ref("features/" + course.id);
        let snapshot = await ref.set(course);
        return true;
    }

    /**
     * Removes a course from the firebase database.
     *
     * @param course A GeoJSON feature representing the course.
     *
     * @returns true if the removal was successful, else an error.
     */
    async removeCourse(course) {
        if (!this._user || !this._user.uid) {
            throw new Error("Non-read operations requires a user");
        }
        if (!course.id) {
            // Doesn't exist
            return true;
        }
        // First, copy it to the graveyard
        let oldRef = firebase.database().ref("features/" + course.id);
        let snap = await oldRef.once("value");
        let newRef = firebase.database().ref("graveyard/" + course.id);
        await newRef.set(snap.val());

        // Then, add it to the deleted database
        let deleted = {
            id: course.id,
            timestamp: firebase.database.ServerValue.TIMESTAMP,
            deletedBy: this._user.uid,
        };
        let deleteRef = firebase.database().ref("deleted/" + course.id);
        let deleteSnapshot = await deleteRef.set(deleted);

        // Finally, delete it from features
        await oldRef.remove();

        return true;
    }
}
