import { memo } from "preact/compat";
import { useContext, useEffect, useRef } from "preact/hooks";

import { MapContext } from "./Map";
import { VectorSourceContext } from "./source/Vector";

import OLFeature from "ol/Feature";
import GeoJSON from "ol/format/GeoJSON";

function Feature({ id = null, geometry, properties, style, projection }) {
    const olFeature = useRef(new OLFeature(null));
    const { source } = useContext(VectorSourceContext);
    const { map } = useContext(MapContext);

    useEffect(() => {
        source.addFeature(olFeature.current);
        return () => {
            source.removeFeature(olFeature.current);
        };
    }, [source]);

    useEffect(() => {
        olFeature.current.setId(id);
    }, [id]);

    useEffect(() => {
        if (geometry && map) {
            const featureProjection = map.getView().getProjection();
            const dataProjection = projection ?? featureProjection;
            const olGeom = new GeoJSON({ featureProjection, dataProjection }).readGeometry(geometry);
            olFeature.current.setGeometry(olGeom);
        } else {
            olFeature.current.setGeometry(null);
        }
    }, [geometry, map, projection]);

    useEffect(() => {
        olFeature.current.setProperties(properties);
    }, [properties]);

    useEffect(() => {
        olFeature.current.setStyle(style ?? null);
    }, [style]);

    return null;
}

export default memo(Feature);
